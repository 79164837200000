import { useCallback, useState } from "react";

import Cookies from "js-cookie";

import { getAPI } from "../helpers/url-helper";
import { decodeToken  } from "../helpers/token-helper";
import MarketLink from "./MarketLink";

import "./css/main.css";

const REGEX = /^0x[a-fA-F0-9]{40}$/;

const Main = () => {
  const [token] = useState(decodeToken(Cookies.get('token')));
  const [isAuthenticated] = useState(!!token);
  const [airDropped] = useState(token && token.payload && token.payload.airdropStatus === 1);
  const [address, setAddress] = useState((token && token.payload && token.payload.walletAddress) || '');
  const [isValid, setIsValid] = useState(true);
  const [addressClaimed, setAddressClaimed] = useState(token && token.payload && token.payload.walletAddress);

  const saveAddress = useCallback(async () => {
    if (!isAuthenticated || !address || !isValid) return;

    const { body: data } = await fetch(getAPI('/wallet'), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token.raw}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ address })
    }).then(r =>  r.json().then(data => ({status: r.status, body: data})));
    if (data && data.success) {
      setAddressClaimed(true);
    }
  }, [isAuthenticated, token, address, isValid, setAddressClaimed]);

  const handleChange = useCallback((e) => {
    const newValue = e.target.value;
    setAddress(newValue);
    setIsValid(REGEX.test(newValue));
  }, []);

  const handleKeyPress = useCallback((event) => {
    if(event.key === 'Enter'){
        saveAddress();
    }
  }, [saveAddress]);

  const renderMain = () => (
    <>
      <div className="image logo" />
      <h1 className="hero-title">
        twit<span>.</span>domains
      </h1>
      {isAuthenticated ? (
        ""
      ) : (
        <h2 className="hero-sub-title">web2 to web3</h2>
      )}
      <p className="bullet-points">
        <span className="point-item">
          <span className="check-mark"></span>On-Chain
        </span>
        <span className="point-item">
          <span className="check-mark"></span>Decentralized
        </span>
        <span className="point-item">
          <span className="check-mark"></span>Open Source
        </span>
        <span className="point-item">
          <span className="check-mark"></span>Self-Sovereign
        </span>
      </p>
      {isAuthenticated ? renderWalletInput() : renderTwitterLogin()}
    </>
  );

  const renderTwitterLogin = () => (
    <>
      <a href={getAPI("/auth/twitter")}>
        <div className="btn btn-primary" type="button">
          <div className="icon icon-twitter-btn" /> Continue with Twitter
        </div>
      </a>
      <div className="text">
        Every Twitter user can claim an airdrop of an exclusive .twit domain that is exactly the same as the handle, 
        e.g., elonmusk.twit for @elonmusk.
      </div>
      {renderWensLink()}
    </>
  );

  const renderWalletInput = () => (
    <>
      <div className="text2">
        Input your wallet address to claim your .twit domain
      </div>
      <div className="input-group">
        <input
          className="value-input address-input"
          placeholder="input your wallet address"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
        <div className={`icon icon-${isValid ? 'check' : 'warn'}`}></div>
        <div
          className={`btn btn-primary${isValid ? '' : ' disabled'}`}
          onClick={saveAddress}
        >
          confirm
        </div>
      </div>
      {renderWensLink()}
    </>
  );

  const renderWensLink = () => (
    <a href="https://app.wens.domains/twit" target="_blank" rel="noreferrer">
      <div className="btn btn-primary" type="button">
        <div className="icon emoji" /> Register all numeric domains
      </div>
    </a>
  );

  const renderSuccess = () => (
    <>
      <h1 className="hero-title">
        Your web3 domain
      </h1>
      <div className="text3">{ airDropped ?
        `Congratulations, @${token.payload.username}! Your domain NFT ${token.payload.username}.twit is already in your wallet ${address}` : 
        `Congratulation, @${token.payload.username}! you've claimed your .twit domain to your wallet address ${address}. Wait for a few minutes, and your domain NFT ${token.payload.username}.twit will show up in your wallet.`}</div>
      <div className="image twit" />
      <a href="https://app.wens.domains/mydomains" target="_blank" rel="noreferrer">
        <div className="btn btn-primary" type="button">
          Go to my domains
        </div>
      </a>
      <MarketLink />
    </>
  );

  return (
    <div className="main">
      <div className="section application">
        { isAuthenticated && addressClaimed ? renderSuccess() : renderMain() }
      </div>
    </div>
  );
};

export default Main;
