import './css/footer.css';

const Footer = () => (
    <div className="footer">
        <div className='social-icons'>
            <a href="https://discord.gg/sczfd89J" target="_blank" rel="noreferrer">
                <div className="icon icon-discord"></div>
            </a>
            <a href="https://twitter.com/TwitNameService" target="_blank" rel="noreferrer">
                <div className="icon icon-twitter"></div>
            </a>
            <a href="https://linktr.ee/twitnameservice" target="_blank" rel="noreferrer">
                <div className="icon icon-linktree"></div>
            </a>
        </div>
        <p>© Copyright 2022 by twit.domains</p>
    </div>
);

export default Footer;
