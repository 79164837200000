import { useCallback, useState } from "react";
import {useLocation} from "react-router-dom";

import Cookies from "js-cookie";

import { getAPI } from "../helpers/url-helper";
import { decodeToken  } from "../helpers/token-helper";
import MarketLink from "./MarketLink";

const REGEX = /^0x[a-fA-F0-9]{40}$/;
const ALLOWED_REFERRER = /^(https:\/\/www.nswap\.com\/|https:\/\/www.nswap\.link\/|https:\/\/nuwton\.io\/|https:\/\/twit\.la\/)/;

const PartnerMain = ({ partner }) => {
  const [token] = useState(decodeToken(Cookies.get('token')));
  const [isAuthenticated] = useState(!!token);
  const [airDropped] = useState(token && token.payload && token.payload.airdropStatus === 1);
  const [addressClaimed, setAddressClaimed] = useState(token && token.payload && token.payload.walletAddress);
  const queryParams = new URLSearchParams(useLocation().search);
  const [address] = useState((token && token.payload && token.payload.walletAddress) || queryParams.get('address'));
  const [partnerName] = useState(partner || queryParams.get('name'));

  const saveAddress = useCallback(async () => {
    if (!isAuthenticated || !address) return;

    const { body: data } = await fetch(getAPI('/wallet'), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token.raw}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ address })
    }).then(r =>  r.json().then(data => ({status: r.status, body: data})));

    if (data && data.success) {
      setAddressClaimed(true);
    }
  }, [isAuthenticated, token, address, setAddressClaimed]);

  if (!address || !REGEX.test(address) || !partnerName /*|| (!isAuthenticated && !ALLOWED_REFERRER.test(document.referrer))*/) {
    window.location.replace('/');
    return;
  }

  const renderMain = () => (
    <>
      <div className="image logo" />
      <h1 className="hero-title">
        twit<span>.</span>domains
      </h1>
      {isAuthenticated ? (
        ""
      ) : (
        <h2 className="hero-sub-title">web2 to web3</h2>
      )}
      <p className="bullet-points">
        <span className="point-item">
          <span className="check-mark"></span>On-Chain
        </span>
        <span className="point-item">
          <span className="check-mark"></span>Decentralized
        </span>
        <span className="point-item">
          <span className="check-mark"></span>Open Source
        </span>
        <span className="point-item">
          <span className="check-mark"></span>Self-Sovereign
        </span>
      </p>
      {isAuthenticated ? renderConfirmation() : renderTwitterLogin()}
    </>
  );

  const renderTwitterLogin = () => (
    <>
      <a href={getAPI(`/auth/twitter/?partner=${encodeURIComponent(partnerName)}&path=${encodeURIComponent(window.location.pathname + window.location.search)}`)}>
        <div className="btn btn-primary" type="button">
          <div className="icon icon-twitter-btn" /> Continue with Twitter
        </div>
      </a>
      <div className="text">
        Every Twitter user can claim an airdrop of an exclusive .twit domain that is exactly the same as the handle, 
        e.g., elonmusk.twit for @elonmusk.
      </div>
      {renderWensLink()}
    </>
  );

  const renderConfirmation = () => (
    <>
      <div className="text3">
        {`@${token.payload.username}, please confirm that ${address} is the wallet address to receive the airdrop of your 
          ${token.payload.username}.twit domain name. The .twit name is an important Web3 identification. So make sure you can access the wallet.`}
      </div>
      <div className="btn btn-primary" type="button" onClick={saveAddress}>
        Confirm
      </div>
      {renderWensLink()}
    </>
  );

  const renderWensLink = () => (
    <a href="https://app.wens.domains/twit" target="_blank" rel="noreferrer">
      <div className="btn btn-primary" type="button">
        <div className="icon emoji" /> Register all numeric domains
      </div>
    </a>
  );

  const renderSuccess = () => (
    <>
      <h1 className="hero-title">
        Your web3 domain
      </h1>
      <div className="text3">{ airDropped ?
        `Congratulations, @${token.payload.username}! Your domain NFT ${token.payload.username}.twit is already in your wallet ${address}` : 
        `Congratulation, @${token.payload.username}! you've claimed your .twit domain to your wallet address ${address}. Wait for a few minutes, and your domain NFT ${token.payload.username}.twit will show up in your wallet.`}</div>
      <div className="image twit" />
      <a href="https://app.wens.domains/mydomains" target="_blank" rel="noreferrer">
        <div className="btn btn-primary" type="button">
          Go to my domains
        </div>
      </a>
      <MarketLink />
    </>
  );

  return (
    <div className="main">
      <div className="section application">
        { isAuthenticated && addressClaimed ? renderSuccess() : renderMain() }
      </div>
    </div>
  );
};

export default PartnerMain;
