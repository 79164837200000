import { Routes, Route, BrowserRouter } from "react-router-dom";

import { Amplify } from 'aws-amplify';

import awsExports from './aws-exports';
import Main from './components/Main';
import PartnerMain from "./components/PartnerMain";
import Footer from './components/Footer';

import './App.css';

Amplify.configure(awsExports);

function App() {
  return (
		<BrowserRouter>
			<div className="app">
				<div className="content">
					<Routes>
						<Route path="/nswap" element={<PartnerMain partner="nswap" />} />
						<Route path="/partner" element={<PartnerMain />} />
						<Route path="*" element={<Main />} />
					</Routes>
				</div>
				<Footer />
			</div>
		</BrowserRouter>
  );
}

export default App;
