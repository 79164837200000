export const decodeToken = (t) => {
  if (!t) return null;
  const [header, payload] = t.split('.');
  try {
    return {
      raw: t,
      header: JSON.parse(window.atob(header)),
      payload: JSON.parse(window.atob(payload))
    }
  } catch (error) {
    console.log('ERROR parsing token', { error });
    return null;
  }
}
